import React from "react";
import { Field, connect, getIn } from "formik";

import FadeIn from "react-fade-in";
import { scrollToNext, ErrorMessage } from "../utilities/utilities";
import Section from "../containers/Section";
import UberSection from "../containers/UberSection";
import "../styles/customStyles.css";
import { Label, Input } from "../styles/InputStyles";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const NomineeDetails = (props) => {
  const errors = getIn(props.formik.errors);
  const touched = getIn(props.formik.touched);
  const setFieldValue = getIn(props.formik.setFieldValue);
  return (
    <>
      <FadeIn>
        <UberSection title="NOMINEE DETAILS">
          <FadeIn>
            <Section>
              <Field
                name="nomineeFName"
                id="nomineeFName"
                type="text"
                onClick={() => scrollToNext(800)}
              >
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Nominee First Name*:</Label>
                    <Input {...field} />
                  </>
                )}
              </Field>
              {touched.nomineeFName && errors.nomineeFName && (
                <ErrorMessage>{errors.nomineeFName}</ErrorMessage>
              )}
              <Field
                name="nomineeLName"
                id="nomineeLName"
                type="text"
                onClick={() => scrollToNext(800)}
              >
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Nominee Last Name*:</Label>
                    <Input {...field} />
                  </>
                )}
              </Field>
              {touched.nomineeLName && errors.nomineeLName && (
                <ErrorMessage>{errors.nomineeLName}</ErrorMessage>
              )}
              <Field name="nomineePhone" id="nomineePhone">
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Nominee Phone Number*:</Label>
                    <PhoneInput
                      countries={["AU"]}
                      defaultCountry="AU"
                      value={field.value}
                      onChange={(phoneNumber) => {
                        setFieldValue(field.name, phoneNumber);
                        return !null ? phoneNumber : null;
                      }}
                      name="nomineePhone"
                    />
                  </>
                )}
              </Field>
              <br />
              <br />
              {touched.nomineePhone && errors.nomineePhone && (
                <ErrorMessage>{errors.nomineePhone}</ErrorMessage>
              )}
              <br />
              <Field
                name="nomineeEmail"
                id="nomineeEmail"
                type="email"
                onClick={() => scrollToNext(800)}
              >
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Nominee Email*:</Label>
                    <Input {...field} />
                  </>
                )}
              </Field>
              {touched.nomineeEmail && errors.nomineeEmail && (
                <ErrorMessage>{errors.nomineeEmail}</ErrorMessage>
              )}
              <Field
                name="nomineeRelationship"
                id="nomineeRelationship"
                type="text"
                onClick={() => scrollToNext(800)}
              >
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Relationship to Client:</Label>
                    <Input {...field} />
                  </>
                )}
              </Field>
            </Section>
          </FadeIn>
        </UberSection>
      </FadeIn>
    </>
  );
};

export default connect(NomineeDetails);
