import React, { useEffect } from "react";
import { Field, getIn, connect } from "formik";
import FadeIn from "react-fade-in";

import Datepicker from "../components/Datepicker/Datepicker";
import { scrollToSection } from "../utilities/utilities";
// import SelectableOption from "../components/SelectableOptions";
import UberSection from "../containers/UberSection";
import Section from "../containers/Section";
import { Label, Input } from "../styles/InputStyles";
import { ErrorMessage } from "../utilities/utilities";

const ApprovedPlanBudget = (props) => {
  const touched = getIn(props.formik.touched);
  const errors = getIn(props.formik.errors);
  // const setFieldValue = getIn(props.formik.setFieldValue);

  // Section References

  useEffect(() => {}, []);
  return (
    <>
      <FadeIn>
        <UberSection title="APPROVED PLAN & BUDGET">
          <div ref={props.refs[0]}>
            <FadeIn>
              <Section>
                <div>
                  <p style={{ marginLeft: "1.5rem", paddingBottom: 0 }}>
                    Plan Start Date:*
                  </p>
                  <Datepicker
                    name="planStartDate"
                    onClick={() => scrollToSection(props.refs[1])}
                  />
                </div>
                {touched.planStartDate && errors.planStartDate && (
                  <ErrorMessage>{errors.planStartDate}</ErrorMessage>
                )}
                <div>
                  <p style={{ marginLeft: "1.5rem", paddingBottom: 0 }}>
                    Plan End Date:*
                  </p>
                  <Datepicker
                    name="planEndDate"
                    onClick={() => scrollToSection(props.refs[2])}
                  />
                </div>
                {touched.planEndDate && errors.planEndDate && (
                  <ErrorMessage>{errors.planEndDate}</ErrorMessage>
                )}
                <div>
                  <p style={{ marginLeft: "1.5rem", paddingBottom: 0 }}>
                    Approved Budget*:
                  </p>
                  <Field
                    name="budgetAmount"
                    id="budgetAmount"
                    type="number"
                    onClick={() => scrollToSection(props.refs[3])}
                  >
                    {({ field }) => (
                      <>
                        <Label htmlFor={field.id}>Amount $:</Label>
                        <Input {...field} />
                      </>
                    )}
                  </Field>
                </div>
                {touched.budgetAmount && errors.budgetAmount && (
                  <ErrorMessage>{errors.budgetAmount}</ErrorMessage>
                )}
              </Section>
            </FadeIn>
          </div>
        </UberSection>
      </FadeIn>
    </>
  );
};

export default connect(ApprovedPlanBudget);
