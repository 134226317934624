import * as Yup from "yup";

const page1Schema = Yup.object().shape({
  participantFName: Yup.string()
    .required("This section is required. Please enter your first name.")
    .nullable(),
  participantLName: Yup.string()
    .required("This section is required. Please enter your last name.")
    .nullable(),
  participantNDISRef: Yup.string()
    .required(
      "This section is required. Please enter your NDIS reference number."
    )
    .nullable(),
  streetNumber: Yup.number()
    .required("This section is required. Please enter your full address.")
    .nullable(),
  streetName: Yup.string()
    .required("This section is required. Please enter your full address.")
    .nullable(),
  citySuburb: Yup.string()
    .required("This section is required. Please enter your full address.")
    .nullable(),
  state: Yup.string()
    .required("This section is required. Please enter your full address.")
    .nullable(),
  postCode: Yup.string()
    .required("This section is required. Please enter your full address.")
    .nullable(),
  participantMobile: Yup.number().when("hasNominee", {
    is: "NO",
    then: Yup.number()
      .required("This section is required. Please enter your phone number.")
      .nullable(),
  }),
  participantDOB: Yup.string()
    .required(
      "This section is required. Please use the datepicker to select a date or type it in this format 'DD/MM/YYYY'."
    )
    .nullable(),
  hasNominee: Yup.string().required(
    "This section is required. Please select yes or no."
  ),
  nomineeFName: Yup.string().when("hasNominee", {
    is: "YES",
    then: Yup.string().required("This is required. Please enter a first name."),
  }),
  nomineeLName: Yup.string().when("hasNominee", {
    is: "YES",
    then: Yup.string().required("This is required. Please enter a last name."),
  }),
  nomineePhone: Yup.number().when("hasNominee", {
    is: "YES",
    then: Yup.number()
      .required("This is required. Please enter a phone number")
      .min(8, "number is too short - should be 8 chars minimum"),
  }),
  nomineeEmail: Yup.string()
    .email("must be a valid email address")
    .when("hasNominee", {
      is: "YES",
      then: Yup.string()
        .email("must be a valid email address")
        .required("This is required. Please enter an email address")
        .typeError("must be a valid email address"),
    }),
  preferredContactMethod: Yup.string().required(
    "This section is required. Please select yes or no."
  ),
});

export default page1Schema;
