import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import 'firebase/auth';
import onFormSubmit from "./onFormSubmit";

const handleSubmit = async (values) => {
  setTimeout(() => {
    let timeStamp = new Date()
      .toLocaleString("en-GB", { timeZone: "Australia/Sydney" })
      .split(" ");
    timeStamp.length = 5;
    timeStamp = timeStamp.join(" ").trim();

    let data = {
      Timestamp: timeStamp,
      ...values,
    };

    const docID = new Date()
      .toISOString()
      .split("T")
      .join(" ")
      .split("Z")
      .join(" ")
      .trim();

    const newDocRef = firebase
      .firestore()
      .collection("springBookings")
      .doc(docID);

    // const auth = getAuth();
    firebase.auth().signInAnonymously()
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("errorcode: " + errorCode + ". " + errorMessage);
      });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        data = { uid: uid, ...data };
        // eslint-disable-next-line no-undef
        newDocRef
          .set(data)
          .then(() => {
            console.log("form submitted!");
          })
          .catch((error) => {
            alert("oops, there's an error: ", error);
          });
      } else {
        console.log("user is signed out...")
      }
    });

    onFormSubmit(values);
  }, 1000);
};

export default handleSubmit;
