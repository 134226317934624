import React, { useEffect, useRef } from "react";
import { Field, connect, getIn } from "formik";
import UberSection from "../containers/UberSection";
import Section from "../containers/Section";
import FadeIn from "react-fade-in";

// import { scrollToSection } from "../utilities/utilities";

import LaundryServices from "../formSections/LaundryServices";
import TimeAndDate from "../formSections/TimeAndDate";
import { ErrorMessage } from "../utilities/utilities";

import SelectableOption from "../components/SelectableOptions";

import "../styles/customStyles.css";
import styled from "styled-components";

const Div = styled.div`
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Page4 = (props) => {
  /* connect formik props to component */
  const values = getIn(props.formik.values);
  const touched = getIn(props.formik.touched);
  const errors = getIn(props.formik.errors);
  const setFieldValue = getIn(props.formik.setFieldValue);

  // Section References
  const laundry = useRef(null);
  const laundryServices = useRef(null);
  const timeAndDate = useRef(null);

  useEffect(() => {
    if (
      values.laundry === "NO" &&
      values.changeBedlinen !== ("" || false || null)
    ) {
      setFieldValue("changeBedlinen", "");
    }
    if (
      values.laundry === "NO" &&
      values.washBedlinen !== ("" || false || null)
    ) {
      setFieldValue("washBedlinen", "");
    }
    if (
      values.laundry === "NO" &&
      values.washClothes !== ("" || false || null)
    ) {
      setFieldValue("washClothes", "");
    }
    if (values.laundry === "NO" && values.ironFold !== ("" || false || null)) {
      setFieldValue("ironFold", "");
    }
    if (values.laundry === "NO" && values.bedlinens !== "") {
      setFieldValue("bedlinens", "");
    }
    if (values.laundry === "NO" && values.linenLoads !== "") {
      setFieldValue("linenLoads", "");
    }
    if (values.laundry === "NO" && values.clothesLoads !== "") {
      setFieldValue("clothesLoads", "");
    }
    if (values.laundry === "NO" && values.ironLoads !== "") {
      setFieldValue("ironLoads", "");
    }
  }, [
    setFieldValue,
    values.bedlinens,
    values.changeBedlinen,
    values.clothesLoads,
    values.ironFold,
    values.ironLoads,
    values.laundry,
    values.linenLoads,
    values.washBedlinen,
    values.washClothes,
  ]);

  return (
    <UberSection title="SERVICES REQUIRED">
      <div ref={laundry}>
        <FadeIn>
          <Section title="Are any in-home laundry services required?*">
            <Field
              name="laundry"
              component={SelectableOption}
              value="YES"
              type="radio"
              label="YES PLEASE"
              id="laundry-yes"
            />
            <Field
              name="laundry"
              component={SelectableOption}
              value="NO"
              type="radio"
              label="NO THANKS"
              id="laundry-no"
            />
            {touched.laundry && errors.laundry && (
              <ErrorMessage>{errors.laundry}</ErrorMessage>
            )}
          </Section>
        </FadeIn>
      </div>
      <div ref={laundryServices}>
        {values.laundry === "YES" && <LaundryServices />}
      </div>
      <div>
        <FadeIn>
          <Section
            title="Window Details*"
            info="Our spring cleaning package includes a thorough house clean with detailed kitchen clean and internal
            window and glass doors clean. Please let us know how many fly screens, windows and glass doors
            you have so we can determine the time required."
          >
            <Div>
              <p>Number of windows:</p>
              <Field name="windowsNum" component="select">
                <option value="">Please Select</option>
                <option value="1 - 4 Windows">1 - 4 Windows</option>
                <option value="5 - 8 Windows">5 - 8 Windows</option>
                <option value="9 - 12 Windows">9 - 12 Windows</option>
                <option value="13 - 16 Windows">13 - 16 Windows</option>
                <option value="17 - 20 Windows">17 - 20 Windows</option>
              </Field>
            </Div>
            <Div>
              <p>Number of glass doors:</p>
              <Field name="doorsNum" component="select">
                <option value="">Please Select</option>
                <option value="1 - 2 Doors">1 - 2 Doors</option>
                <option value="3 - 4 Doors">3 - 4 Doors</option>
                <option value="5 - 6 Doors">5 - 6 Doors</option>
                <option value="7 - 8 Doors">7 - 8 Doors</option>
                <option value="9 - 10 Doors">9 - 10 Doors</option>
              </Field>
            </Div>
            <Div>
              <p>Number of flyscreens:</p>
              <Field name="flyscreensNum" component="select">
                <option value="">Please Select</option>
                <option value="No Flyscreens">No Flyscreens</option>
                <option value="1 - 4 Flyscreens">1 - 4 Flyscreens</option>
                <option value="5 - 8 Flyscreens">5 - 8 Flyscreens</option>
                <option value="9 - 12 Flyscreens">9 - 12 Flyscreens</option>
                <option value="13 - 16 Flyscreens">13 - 16 Flyscreens</option>
                <option value="17 - 20 Flyscreens">17 - 20 Flyscreens</option>
              </Field>
            </Div>
          </Section>
        </FadeIn>
      </div>
      <div ref={timeAndDate}>
        <TimeAndDate />
      </div>
    </UberSection>
  );
};

export default connect(Page4);
