import React from "react";
//import { connect, getIn } from "formik";
//import Section from "../containers/Section";
import FadeIn from "react-fade-in";
// import Options from "../components/Buttons/SelectOptions";
import DaysAndTimes from "../components/Buttons/DaysAndTimes";
//import { ErrorMessage } from "../utilities/utilities";
import "../styles/customStyles.css";

const TimeAndDate = (props) => {
  // const errors = getIn(props.formik.errors);
  //const touched = getIn(props.formik.touched);
  // const values = getIn(props.formik.values);
  // const name = getIn(props.formik.name);

  // Section References
  // const startDate = useRef(null);
  //const servicePriorities = useRef(null);

  return (
    <>
      <div>
        <FadeIn>
          <DaysAndTimes />
        </FadeIn>
      </div>

      {/* currently not needed */}

      {/* <div>
        <FadeIn>
          <Section title="How soon would you like these services to start?*">
            <Field name="startDate" component="select">
              <option value="">Select an option</option>
              <option value="ASAP">ASAP</option>
              <option value="Next Week">Next Week</option>
              <option value="Within the Month">Within the Month</option>
            </Field>
            {touched.startDate && errors.startDate && (
              <ErrorMessage>{errors.startDate}</ErrorMessage>
            )}
          </Section>
        </FadeIn>
      </div> */}
      {/* <div ref={servicePriorities}>
        <FadeIn>
          <Section
            title="Is there anything else you would like us to know?"
            info="i.e. areas that need particular attention in the home"
          >
            <Field component="textarea" name="servicePriorities" rows="10" />
          </Section>
        </FadeIn>
      </div> */}
    </>
  );
};

export default TimeAndDate;
