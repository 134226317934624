import * as Yup from "yup";

const page4Schema = Yup.object().shape({
  laundry: Yup.string().required("This is required. Please pick yes or no."),
  windowsNum: Yup.string().required(
    "This is required. Please make your selection."
  ),
  doorsNum: Yup.string().required(
    "This is required. Please make your selection."
  ),
  flyscreensNum: Yup.string().required(
    "This is required. Please make your selection."
  ),
});

export default page4Schema;
