// import axios from "axios";
import $ from "jquery";
/* Send form data to Google Sheets */
// Variable to hold request
var request;

// Bind to the submit event of our form
const onFormSubmit = (values) => {
  // Abort any pending request
  if (request) {
    request.abort();
  }

  // setup some local variables
  var $form = $("#springCleanForm");

  // Let's select and cache all the fields
  var $inputs = $form.find("input, select, button, textarea");

  // Serialize the data in the form
  var serializedData = $.param(values);

  // Let's disable the inputs for the duration of the Ajax request.
  // Note: we disable elements AFTER the form data has been serialized.
  // Disabled form elements will not be serialized.
  $inputs.prop("disabled", true);

  // Fire off the request to /form.php
  request = $.ajax({
    url:
      "https://warm-ridge-25369.herokuapp.com/https://script.google.com/macros/s/AKfycbz-hZ6CJGCwGP7gG2CYVhcXEx_sc8ARW7CU_ovl7WQt-Bh0rKjk/exec",
    type: "post",
    data: serializedData,
  });

  request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

  // Callback handler that will be called on success
  request.done(function () {
    window.location = "http://www.sistability.com.au/booking-form-thank-you";
  });

  // Callback handler that will be called on failure
  request.fail(function (jqXHR, textStatus, errorThrown) {
    // Log the error to the console
    console.error("The following error occurred: " + textStatus, errorThrown);
    alert(
      "There was an error submitting your form. Please refresh the page and try again."
    );
  });

  // Callback handler that will be called regardless
  // if the request failed or succeeded
  request.always(function () {
    // Reenable the inputs
    $inputs.prop("disabled", false);
  });
};

export default onFormSubmit;
